.gwIssuesContainer {
  --gw-issues-message-radius: 2px;
  --gw-issues-message-border: 100%;
  --gw-issues-message-flex: 1;
  --gw-issues-icon-border: 1px;
  margin-bottom: var(--GW-SPACING-LG);

  .gwIssuesMessage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--gw-issues-message-radius);

    &::before {
      margin: var(--GW-SPACING);
      display: block;
      font-family: "FontAwesome";
      line-height: calc(2 * var(--GW-LINE-HEIGHT));
      font-size: var(--GW-FONT-SIZE-LG);
      flex: none;
      text-align: center;
      height: calc(2 * var(--GW-LINE-HEIGHT));
      width: calc(2 * var(--GW-LINE-HEIGHT));
      border-radius: var(--gw-issues-message-border);
    }
  }

  .gwIssuesError {
    border: var(--gw-issues-icon-border) solid var(--GW-COLOR-STROKE);
    background-color: var(--GW-COLOR-BACKGROUND-2);

    &::before {
      content: var(--icon-font-awesome-error);
      color: var(--GW-COLOR-ALERT-ERROR-HOVER);
      background-color: var(--GW-COLOR-STROKE);
    }
  }

  .gwIssuesWarning {
    border: var(--gw-issues-icon-border) solid var(--GW-COLOR-ALERT-WARNING-HOVER);
    background-color: var(--GW-COLOR-ALERT-WARNING);

    &::before {
      content: var(--icon-font-awesome-warning);
      color: var(--GW-COLOR-ALERT-WARNING);
      background-color: var(--GW-COLOR-ALERT-WARNING);
    }
  }

  .gwIssuesMessageContainer {
    flex: var(--gw-issues-message-flex);
    padding-left: var(--GW-SPACING-XXL);
  }

  .gwIssuesCloseIcon {
    margin: var(--GW-SPACING);
    align-self: flex-start;
    color: var(--GW-COLOR-TEXT-2-ACTIVE);
    font-size: var(--GW-FONT-SIZE-XL);
    cursor: pointer;
  }

  .gwIssuesCloseIcon:hover {
    color: var(--GW-COLOR-TEXT-1-ACTIVE);
    background-color: var(--GW-COLOR-ALERT-WARNING-ACTIVE);
  }
}
