@import "~@jutro/theme/assets/sass/helpers";
$--GW-BOX-SHADOW-FOCUS: 0 0 0px 2px #000 !important;
.backdiv {
  opacity: 0.4;
  background: gray;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  z-index: 200;
}

.editorStyle {
  z-index: 300;
  position: relative;
}

body {
  line-height: var(--GW-LINE-HEIGHT);
  font-family: var(--GW-FONT-FAMILY);
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
  color: var(--GW-COLOR-TEXT-1);
  margin: 0;
}

h2{
  font-weight: bold !important;
}

.jut__Container__container {
  --container-margin-x: 0;
  --container-margin-y-top: 0;
  --container-margin-y-bottom: 20px;
  --container-max-width: none;
}

.osbcMainContainer {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2.5em; 
    padding-right: 2.5em;
    outline: none;
}
.wsib_ospcMainContainer {
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em; 
    padding-right: 1em
}
.smallSpace {
  margin-bottom: 5px !important;
}
.normalSpace {
  margin-bottom: var(--GW-SPACING) !important;
}
.largeSpace {
  margin-bottom: var(--GW-SPACING-XL) !important;
}
.hugeSpace {
  margin-bottom: var(--GW-SPACING-XXXL) !important;
}
.userInput {
  label {
    --field-label-font-weight: bold;
  }
  input {
    --input-border-radius: 3px;
    --input-border-color: #5e6a71;
    --input-border-width: 1px;
    --input-height: 34px;
    --input-padding-x: 5px;
    --input-padding-y: 5px;
    max-width: 241px;
  }
}

.userInput.inputError {
  input {
    --input-border-color: var(--warning-color);;
  }
  span {
    --checkbox-border-color: var(--warning-color);;
  }
}

a {
  vertical-align: baseline !important;
}

a.bluelink {
  text-decoration: underline !important;
  
  &:hover {
    text-decoration: none !important;
    --link-text-decoration: none !important;
  }
}

a.bluelink,
a.bluelink:hover {
  letter-spacing: 0;
  text-align: left;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0D73B8;
  opacity: 1;
}

a.bluelink:focus-visible,
a.bluelink:focus {
  outline: none;
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS)
    var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000;
}

figure {
  flex-shrink: 0;
}

button.custom {
  --button-bg-color: transparent;
  --button-color: red;
  --GW-COLOR-BRAND-1: transparent;
  --GW-COLOR-BRAND-1-GRADIENT: transparent;
  --button-hover-color: #0D73B8;
  --button-hover-bg-color: #fff;
  --button-hover-bg-image: transparent;
  --button-hover-border-color: #0D73B8;
  --button-active-color: #0D73B8;
  --button-active-bg-color: transparent;
  --button-active-bg-image: transparent;
  --button-active-border-color: transparent;
  --button-font-weight: 600;
  box-shadow: none;
  padding-left: 15px;
  padding-right: 15px;
}

.jut__Button__primary {
  border: 1px solid #0D73B8 !important;
  min-width: 120px;
}

.jut__Button__tertiary {
  border: none !important;
  :first-child {
    border-bottom: 1px solid transparent !important;
  }
  border-radius: 0px !important;
}

.jut__Button__primary:hover, .jut__Button__secondary:hover  {
  background-color: #292929 !important;
  color: #fff !important;
  border: 1px solid #292929 !important;
}

.jut__Button__primary:focus:hover,
.jut__Button__secondary:focus:hover {
  background-color: #292929 !important;
  color: #fff !important;
  border: 1px solid #292929 !important;
}

.jut__Button__primary:focus, .jut__Button__secondary:focus {
  background-color: #292929 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  outline: 2px solid black;
}

.jut__Button__secondary:hover {
    box-shadow: inset 0 0 0 calc(0.125 * var(--GW-SPACING)) #292929, #292929;
    //box-shadow: inset 0 0 0 calc(0.125 * var(--GW-SPACING)) var(--GW-COLOR-BRAND-1-GRADIENT), var(--GW-BOX-SHADOW-OUTER-2)
}
.jut__Button__tertiary:hover {
    .helpTxt, .changeSelectionLink {
        text-decoration: none !important;
    }
}

.jut__Button__tertiary:hover {
    background-color: transparent !important;
    color: #0D73B8 !important;
  }

.jut__Pagination__paginationSelect {
  border: none !important;
  text-decoration: none !important;
  border-radius: 8px !important;
  :first-child {
    text-decoration: none !important;
  }
}
.jut__FileUploadField__wrapper button {
  background-color: #0D73B8 !important;
  border: 1px solid #0D73B8 !important;
  color: #fff !important;
}
.jut__FileUploadField__wrapper button:hover {
  background-color: #fff !important;
  border: 1px solid #0D73B8 !important;
  color: #0D73B8 !important;
}

.jut__Pagination__paginationSelect:hover {
  border: none !important;
  text-decoration: none !important;
  border-radius: 8px !important;
  :first-child {
    text-decoration: none !important;
  }
}

.jut__InputField__input {
  height: 34px !important;
}

.jut__TextAreaField__textArea {
  height: auto !important;
}

button.custom:focus {
  --button-z-index: 10;
  box-shadow: 0 0 0px 2px #000 !important;
  --button-focus-color: #0D73B8;
  --button-focus-bg-color: transparent;
  --button-focus-bg-image: transparent;
  --button-focus-border-color: transparent;
  outline: none;
}
button.custom:active,
button.custom:active:focus {
  box-shadow: none;
  outline: none;
}
div.jut__GenericSelectControl__option {
  padding: var(--generic-select-option-padding-y)
    var(--generic-select-option-padding-x);
  line-height: 1;
  font-size: 14px;
  color: var(--generic-select-option-color);
  cursor: pointer;
}

.warningMessage {
  color: var(--warning-color);
  font-weight: bold;
}

.savedDeleted {
  font-weight: bold
}

.jut__DataTable__noDataMessage {
  text-align: left;
  padding-left: 16px;
  font-size: 14px;
  color: #333 !important;
  @media (max-width: 395px) {
    width: 200px !important;
    }
}

.jut__DataTable__tableHeaderCell {
  .jut__DataTable__headerMultiline {
    display: flex !important;
  }
}

.noScroll {
  position: relative;
  overflow: hidden;
}

#iAmDropdown-wrapper,
#responsiveiAmDropdown-wrapper {
  .css-1hb7zxy-IndicatorsContainer {
    width: 26px;
    border-radius: 50%;
    height: 26px;
    align-self: center;
    .jut__GenericSelectControl__selectIcon {
      padding-left: 0px;
      padding-right: 0px;
      margin: auto;
    }
  }
}

.menuClose {
  .mir {
    font-size: 40px;
  }
}

//ModalNextProvider styles

.jut__ModalHeader__modalHeader {
  --modal-header-bg: none !important;
  --modal-header-font-weight: 700;
  margin: auto;
}

.jut__ModalNext__modal {
  text-align: center !important;
  --modal-min-height: auto !important;
  --modal-min-width: 45vh !important;
}

.jut__ModalFooter__modalFooter {
  --modal-footer-status-shadow-size: none;
  padding-bottom: 76px;
  .jut__Button__primary {
    border: none !important;
  }
  
  .jut__Button__tertiary {
    color: #fff !important;
    background-color: #0D73B8 !important;
    border: 1px solid #0D73B8 !important;
    :first-child {
      border-bottom: none !important;
    }
    border-radius: 24px !important;
  }
  
  .jut__Button__tertiary:hover {
    background-color: #fff !important;
    color: #0D73B8 !important;
    :first-child {
      border-bottom: none !important;
    }
  }
  
  .jut__Button__tertiary:focus:hover,
  .jut__Button__tertiary:focus {
    background-color: #0D73B8 !important;
    color: #fff !important;
    :first-child {
      border-bottom: none !important;
    }
  }
  
//   .jut__Button__primary{
//     background-color: #fff !important;
//     color: #0076bf !important;
//     border: none !important;
//     background-image: none !important;
//     box-shadow: none !important;
//     border-radius: 0px !important;
//   }

//   .jut__Button__primary:hover {
//     border-radius: 0px !important;
//     background-color: #fff !important;
//     color: #0076bf !important;
//     :first-child {
//       text-decoration: underline !important;
//     }
//   }
}

.jut__ModalHeader__modalHeaderTitleLayout {
  margin: auto auto auto 0 !important;
}

.jut__Flex__gwDirectionRow {
  flex-direction: column;
}

.jut__Button__primary, .jut__Button__secondary {
  margin-bottom: 10px;
}

.claimOverviewMainContainer {
  @extend .osbcMainContainer;
}

.rightMainContainer {
  @extend .osbcMainContainer;
}
.rightMainContainer {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.claimDetailDocumentContainer {
  width: 100%;
  padding-left: 2.5em !important;
  padding-right: 2.5em !important;
}

.customRadio {
  .jut__FieldLabel__primaryLabel {
    font-weight: 600;
    white-space: nowrap;
  }
  .jut__RadioButton__radioButton {
    display: inline-block;
  }
  .jut__FieldComponent__reversed > :first-child,
  .jut__FieldComponent__reversed > :last-child {
    flex: none !important;
  }
}

.customClaimTypeRadio {
  .jut__FieldLabel__primaryLabel {
    white-space: nowrap;
    font-size: 14px;
  }
  .jut__RadioButton__radioButton {
    display: inline-block;
  }
  .jut__FieldComponent__reversed > :first-child,
  .jut__FieldComponent__reversed > :last-child {
    flex: none !important;
  }
}

.jut__Accordion__cardBody {
  margin: 0px;
  padding: 10px 0px;
}

.exactMatchContainer {
  .jut__CheckboxField__checkbox {
    justify-content: end !important;
  }
}

#DivPara {
  a {
    vertical-align: text-top;
  }
}
.w50 {
  width: 50%;
}

#linkUploadDocumentAgain,
#linkUploadDocumentAgain:hover,
#linkUploadDocumentAgain:focus,
#linkUploadDocumentAgain:focus-visible {
  color: #fff;
  text-decoration: underline;
}
// styles for Active Tab of sidenav
li.active {
  background-color: #e5f1f8 !important;
}

#latestPaymentIssuesDatatable,
#currentBenefitDatatable,
#returnToWorkDatatable {
  .jut__Pagination__pageSizeSelectWrapper {
    display: none;
  }
  .jut__Pagination__paginationNav {
    width: 100%;
    justify-content: flex-end;
  }
  .jut__DataTable__noDataMessage {
    font-size: 14px;
    font-weight: normal;
    color: #333;
  }
  .ReactTable .rt-thead div.rt-th.-cursor-pointer::after {
    display: none !important;
  }
}

#latestPaymentIssuesDatatable,
#returnToWorkDatatable {
  .jut__Pagination__pagination {
    display: none !important;
  }
}
#mobilityDevicesDatatable,
#therapyDatatable {
  .jut__Pagination__pagination {
    font-size: 16px !important;
  }
  .jut__Pagination__paginationNavButton {
    font-size: 16px !important;
  }
  .jut__Pagination__paginationSelect {
    font-size: 16px !important;
  }
}
.w100 {
  width: 100%;
}

.jut__Accordion__accordionToggle {
  min-height: 40px;
  padding: 20px 0px;
  padding-top: 0px;
}

@include gw-breakpoint-down(tablet) {
  .jut__ModalNext__modal {
    --modal-min-height: 25vh !important;
    --modal-min-width: 38vh !important;
  }

  .claimOverviewMainContainer {
    padding: 0px;
  }
  .rightMainContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

/*for tablet css start*/
@include gw-breakpoint-only(tablet) {
  .jut__DataTable__dataTable {
    width: 100%;
    word-break: break-word;
    min-width: 350px;
    min-height: 256px;
    table-layout: auto;
    @media (max-width: 320px) {
      min-width: 200px;
  }
  }
  #responsiveiAmDropdown-wrapper {
    .jut__GenericSelectControl__control,
    .css-1uccc91-singleValue {
      color: white !important;
    }
  }
  .divScrollParent {
    overflow-x: auto;
  }
  .rightMainContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .w50 {
    width: 100%;
  }
}
/*for tablet css end*/
/*for phone css start*/
@include gw-breakpoint-only(phone) {
  .jut__DataTable__dataTable {
    width: 100%;
    word-break: break-word;
    min-width: 350px;
    min-height: 256px;
    table-layout: auto;
    @media (max-width: 320px) {
      min-width: 200px;
  }
}
  .jut__Pagination__pagination {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
  }
  .jut__Pagination__pageSizeSelectWrapper {
    justify-content: flex-start;
  }
  #responsiveiAmDropdown-wrapper {
    .jut__GenericSelectControl__control,
    .css-1uccc91-singleValue {
      color: white !important;
    }
  }
  .divScrollParent {
    overflow-x: auto;
  }
  .rightMainContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .w50 {
    width: 100%;
  }
}

/*for phone css end*/

#SubmittedDocumentsDataTable span {
  word-wrap: break-word !important;
}

#claimTombstone {
  .jut__InputField__input {
    --input-border-width: none !important;
  }
}

a.linkColor {
  letter-spacing: 0;
  text-align: left;
  font-size: 16px;
  font-family: Muli, Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0D73B8;
  opacity: 1;
}
a.linkColor:focus-visible,
a.linkColor:focus {
  outline: none;
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS)
    var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000;
}
.jut__Button__button:focus {
  box-shadow: none !important;
    //var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000 !important;
}
.noDataMessage {
  font-size: 14px;
  font-weight: normal !important;
  color: #333 !important;
}

[for='ddlSubject'],
[for='txtBody'] {
  .jut__FieldLabel__primaryLabel {
    font-weight: 600;
    white-space: nowrap;
  }
}

/*Modal popup*/
.jut__ModalHeader__modalWarningStatus {
  --modal-header-bg: #2e98d9;
}
.jut__ModalFooter__modalFooter {
  .jut__Flex__gwDirectionRow {
    flex-direction: row !important;
  }
  .jut__Button__primary {
    //margin-bottom: 0px;
  }
}

.customHyperLink {
  font-size: 16px !important;
}

.jut__Modal__modalStateInfoIcon {
  display: none !important;
}

.jut__Modal__modalFooter {
  text-align: center !important;
}
.jut__Modal__message {
  text-align: center !important;
}
.jut__Modal__modalHeaderContent {
  justify-content: center !important;
}
.customAccordian {
  .jut__Accordion__cardHeading {
    border-bottom: 1px solid #333 !important;
  }
}
.jut__Modal__modalStateInfo::before {
  background-color: unset !important;
}
.jut__Modal__modalInner {
  .jut__Chevron__chevron.mir {
    color: #0076bf !important;
  }
}

.jut__Accordion__header {
  font-weight: normal !important;
  left: 0 !important;
}

.jut__Chevron__chevronDefault {
  transform: rotate(90deg);
}

.jut__Chevron__open {
  transform: rotate(-90deg) !important;
}

.jut__Accordion__accordion .jut__Accordion__headerFocused {
  box-shadow: unset !important;
  // border: 1px dashed #888 !important;
  border: 2px solid #000 !important;
}
.jut__IconButton__iconButton:focus,
.jut__IconButton__iconButton:focus-visible {
  border: 2px solid #000 !important;
  box-shadow: unset !important;
}

.jut__RadioButton__radioButton
  input[type='radio']:checked
  + .jut__RadioButton__label::before {
   box-shadow: 0 0 0px 2px white, 0 0 0px 4px #292929 !important;
}

input:focus,
textarea:focus,
.jut__FieldComponent:focus,
.jut__GenericSelectControl__controlFocused,
.jut__GenericSelectControl__control:focus,
.jut__Link__link:focus {
  box-shadow: 0 0 0px 2px #000 !important;
}

div.jut__GenericSelectControl__optionFocused {
  border: 2px solid #0076BF !important;
  background-color: #FFF;
}

div.jut__GenericSelectControl__optionFocused.jut__GenericSelectControl__optionSelected {
  background-color: #E9F9FF;
}

.jut__CurrencyField__currency {
    input:focus {
        box-shadow: none !important;
    }
}
.jut__CurrencyField__inputContainer:focus-within {
    box-shadow: 0 0 0px 2px #000 !important;
}

.jut__GenericSelectControl__controlFocused,
.jut__GenericSelectControl__control:focus,
.jut__Link__link:focus {
  box-shadow: 0 0 0px 2px #000 !important;
  input:focus {
    box-shadow: unset !important;
  }
}

.jut__ModalHeader__modalWarningStatus {
  --modal-header-bg: #fff;
}

.jut__ModalHeader__icon {
  display: none !important;
}

.customTertiary {
  font-weight: 900 !important;
  margin-left: 25px;
}

@include gw-breakpoint-down(tablet) {
  .customTertiary {
    margin-left: 0px !important;
  }
}

.ReactTable .-loading > div {
  display: none !important;
}

.semiBold {
  .jut__Link__link .jut__Link__text {
    color: #0D73B8 !important;
  }

  font-weight: 600 !important;
}

.jut__DataTable__dataTable{
  min-width: min-content;
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell {
  margin: 10px !important;
  word-break: keep-all !important;
  min-width: min-content !important;
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableCell{
  margin: 10px !important;
  word-break: keep-all !important;
  min-width: min-content !important;
}

.jut__ModalFooter__modalFooter .jut__Flex__gwDirectionRow {
  flex-direction: column !important;
}

.jut__ModalBody__modalBody {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: -10px;
  min-height: 100px !important;
  h1 {
    font-weight: normal;
    font-size: 34px;
    max-width: 400px;
    // OSPC:
    text-align: center;
  }
  p {
    max-width: 400px;
    text-align: center;
  }
  div {
    text-align: left;
    white-space: pre-line;
  }
}

.jut__Grid__grid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  overflow-y: auto;
  overflow-x: hidden;
}


.jut__ModalFooter__modalFooter .jut__Button__primary {
  //margin: 0px !important;
}

.jut__ModalHeader__modalHeaderLayout {
    padding-top: 10px !important;
}

.jut__ModalFooter__modalFooter .jut__Button__tertiary {
    margin-bottom: -50px;
    background-color: #fff !important;
    color: #0D73B8 !important;
}
.jut__ModalFooter__modalFooter .jut__Button__tertiary:hover {
    color: #fff !important;
    background-color: #292929 !important;
    border: 1px solid #292929 !important;
    border-radius: 24px !important;
}


.jut__ModalFooter__modalFooter .jut__Button__primary,  .jut__ModalFooter__modalFooter .jut__Button__secondary{
    margin-bottom: -50px !important;
    margin-left: 20px !important;
    margin-right: 22px !important;
}

//OSPC modal change - eg: Navigate away in Claim details
//OSPC modal change - eg: Navigate away in Claim details
div[id^='modalNext'] + div button.jut__Button__primary, div[id^='modalNext'] + div button.jut__Button__tertiary{
    margin-bottom: 10px !important;
}

div[id^='modalNext'] + div button.jut__Button__primary {
    margin-right: var(--flex-gap-md) !important;
}

@media (max-width: 580px) {
    .jut__ModalHeader__title {
        font-size: medium;
    }

    .jut__ModalFooter__modalFooter {
      --modal-footer-height: auto !important;
    }
}

.jut__Pagination__selectedPageButton{
  font-weight: bold;
  background-color: #003359;
  --pagination-button-font-color: #ffffff;
}

#card1 {
  .jut__Accordion__header {
    font-weight: 600 !important;
    font-size: 27px;
  }
  .jut__CheckboxField__checkboxElement.jut__CheckboxField__checkboxElementSmall {
    width: 14px;
    height: 14px;
    line-height: 14px;
    top: 3px;
  }
}

.jut__DropdownSelectField__dropdownSelect.jut__DropdownSelectField__sizeableDropdown.jut__DropdownSelectField__inputSmall {
  padding: 0px !important;
}

.jut__Modal__dismissButton {
   display: none;
}

.jut__Modal__modalFooter {
  button {
    border-radius: 40px !important;
  }
}

.jut__ModalHeader__closeButton {
   display: none !important;
}

.jut__Modal__iconWrapper{
  display: none;
}

.jut__ModalHeader__title {
  white-space: pre-line;
  text-align: left;
}

.onBoarding-modal-en {
  overflow-y: auto;
  overflow-x: hidden;

  .jut__ModalHeader__modalHeaderTitleLayout {
    width: 100%;
  }
  .jut__ModalHeader__title {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: left;
    white-space: pre-line;
    padding-left: 5%;

  }

  .jut__ModalBody__modalBody {
    p {
     
    text-align: left;
  
    }
  }
}

.onBoarding-modal-fr {
    overflow-y: auto;
    overflow-x: hidden;

  .jut__ModalHeader__title {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: left;
    white-space: pre-line;
    padding-left: 5%;
  }

  .jut__ModalBody__modalBody {
    p {
     
    text-align: left;
  
    }
  }
}

.privacy-container + div {
   button {
    display: none !important;
   }
}


#inputStartDateErrorContainer {
  color: red !important;
  font-weight: bold !important;
}

#reviewLTEReason-sub {
  padding-top: 1rem !important;
}
.jut__InputField__input.invalid {
    color: black;
    border-color: var(--GW-COLOR-BORDER);
}

.wsibSecondaryLabel{
    color: #5E6A71;
    font-size: var(--GW-FONT-SIZE-SM);
    line-height: var(--WSIB-LINE-HEIGHT-XS);
}

.wsibLabelStyle{ 
    margin-bottom: var(--GW-SPACING-XS) !important;
}

.wsibDivMaginTop {
    margin-top: 2rem;
}

.label-bold {
    font-weight: bold;
}

.jut__FieldComponent__fieldComponent, #dropdownDobContainer {
    margin-bottom: var(--GW-SPACING-XS) !important;
}

#provincePostalCodeContainer > div {
    margin-bottom: var(--GW-SPACING) !important;
}

#dropdownModifiedDateContainer {
    margin-bottom: var(--GW-SPACING-LG) !important;
}

.jut__FieldLabel__secondaryLabel{
    line-height: var(--WSIB-LINE-HEIGHT-XS);
}

.suggestion-item{
    line-height: var(--WSIB-LINE-HEIGHT-XS);
}

.dropdownDateInput {
    // flex-grow: 1;
    padding-right: 8px;
    margin-bottom: 0px !important;
}

.jut__GenericSelectControl__genericSelect {
    max-width: 460px;
    //height: 34px;
}

textarea{
    max-width: 740px;
}

input {
    max-width: 460px;
    height: 34px;
}

[class*=provincePostalCodeContainer] {
    max-width: 460px;
}

.wsibNoPaddingRight{
    padding-right: 0px;
}

.customThemeButton__button {
    --button-font-weight: 500 !important;
    border-radius: 24px !important;
    position: inherit;
}

.jut__Button__secondary {
    border: 2px solid;
    --button-box-shadow: none;
    min-width: 120px;
    //margin-right: 40px;
}

.jut__Button__primary {
    margin-right: 40px;
}

.jut__Button__primary {
    --button-box-shadow: none !important;
}
.jut__Button__primary:focus-visible {
    //box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS) var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000 !important;
    box-shadow: none !important;
    --button-focus-box-shadow-outer: none !important;
    --button-focus-box-shadow-inner: none !important;
}

// .customThemeButton__button > span {
//     position: sticky;
//     top: 12px;
//     left: 40px;
// }

#onBoardSaveId, #onBoardContinueId {
    margin-left: 0px !important;
}

#sessionSecButton, #sessionPriButton {
    margin-bottom: 16px !important;
    min-width: 200px;
    margin-left: 40px !important;
}

.headingDesc {
    margin-bottom: 48px !important;
}

.WSIBmarginBottom {
    margin-bottom: 8px !important;
}

.jut__RadioButtonCardField__secondaryLabelPortrait{
    line-height: 19.2px !important;
}

.jut__ModalHeader__title {
    line-height: 34px !important;
}

#claimsNumberBody, #injutryTextId {
    margin-top: 0px !important;
}

.WSIBmargin1 {
    margin-top: 16.5px !important;
    margin-bottom: 0px !important;
}

#addButtonContainer {
    margin-bottom: 24px !important;
}

.WSIBHeaderMarginTop {
    margin-top: 3px !important;
}

.WSIBmarginTop {
    margin-top: 8px !important;
}

.WSIBHeader {
    margin-bottom: 11.85px !important;
    padding-bottom:0px !important;
    margin-top:3px !important;
}

.WSIBmarginTop2 {
    margin-top: 16px !important;
}

.errorMessageLink {
    color: var(--warning-color) !important;
}
// #radiobuttonFollowupQuestion div:last-child label{
//     margin-bottom: 0px !important;
// } 

.errorMsg + .jut__FieldMessage__error {
    font-weight: 600 !important;
    color: var(--warning-color);
}

.headerText{
    padding-left: 50px!important;
    text-align: right;
  }
  
  .actions2_edit{
    button{
      opacity: 1 !important;
      margin-top: -7px !important;
    }
    button:focus {
      box-shadow: 0 0 0px 2px #000 !important;
    }
  } 

  .actions2_delete{
    button{
      opacity: 1 !important;
      margin-top: -7px !important;
    }
    button:focus {
      box-shadow: 0 0 0px 2px #000 !important;
    }
  }

    // the below 2 styles used for body part datatable delete button
    .headerText2{
      padding-left: 25px!important;
      text-align: center !important;
    }

  .action_delete2_datatable {
    button {
      opacity: 1 !important;
      min-width: unset !important;
    }
    button:focus {
      box-shadow: 0 0 0px 2px #000 !important;
    }
  }

  span.jut__Link__text {
    font-size: 14px !important;
  }

  #herder-main-contdfdfainer {
    border-top: 1px solid #CDCFD0;
  }

  .jut__ModalNext__modal {
    width: 515px;
}

.diModalHeader {
    padding-left: 56px;
    padding-right: 56px;
    text-align: center !important;
    padding-top: 40px !important;
}

.diModalSubHeader {
  font-size: 16px !important;
}

.diModalContent {
    padding-left: 52px;
    padding-right: 52px;
    text-align: center !important;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.diButtonWidth {
  min-width: 211px;
  @media (max-width: 359px) {
    min-width: 200px !important;
  }
}

.jut__ModalBody__modalBody div {
    text-align: center;
}

.jut__ModalHeader__title {
    text-align: center;
}

.jut__ModalBody__modalBody.jut__ModalBody__hasVerticalScrollbar {
    box-shadow: none;
}

.jut__ModalBody__modalBody {
    min-height: 100px !important;
}

.jut__Main__container::before {
  display: none;
}

.jut__Main__container::after {
  display: none;
}

@include gw-breakpoint-only(phone) {
    .jut__ModalHeader__modalHeaderTitleLayout {
        padding-top: 0px !important;
    }
    .diModalContent, .diModalHeader {
        padding-left: 32px;
        padding-right: 32px;
    }
    .jut__ModalFooter__modalFooterFlexLayout {
        padding-bottom: 0px;
    }
    .diModalHeader {
        font-size: 16px !important;
        padding-top: 0px !important;
    }
    .diModalContent {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@include gw-breakpoint-only(tablet) {
    .diModalContent, .diModalHeader {
        padding-left: 32px;
        padding-right: 32px;
    }
    .jut__ModalFooter__modalFooterFlexLayout {
        padding-bottom: 0px;
    }
    .jut__ModalHeader__modalHeaderTitleLayout {
        padding-top: 0px !important;
    }
    .diModalHeader {
        font-size: 16px !important;
        padding-top: 0px !important;
    }
    .diModalContent {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 511px) {
    .diModalContent, .diModalHeader {
        padding-left: 32px;
        padding-right: 32px;
    }
    .jut__ModalFooter__modalFooterFlexLayout {
        padding-bottom: 0px;
    }
    .jut__ModalHeader__modalHeaderTitleLayout {
        padding-top: 0px !important;
    }
    .diModalHeader {
        font-size: 16px !important;
        padding-top: 0px !important;
    }
    .diModalContent {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .ReactModal__Content {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .jut__ModalBody__modalBody {
        margin-top: 8px !important;
    }
}

#divFollowupQuestionContainer .jut__FieldLabel__fieldLabelContainer {
    margin-bottom: 0px;
}

a {

  &:hover {
    text-decoration: none !important;
  }

  &:focus {
    text-decoration: none !important;
  }

  &:active {
    text-decoration: none !important;
  }
}

.jut__Link__link .jut__Link__text {
  
  &:focus {
    text-decoration: none !important;
  }
  
  &:hover {
    text-decoration: none !important;
  }
  
  &:active {
    text-decoration: none !important;
  }
}

.hiddenAriaOnlyContent {
    position: absolute;
    left: -9999px;
    height: 1px;
    margin: 0px;
    padding: 0px;
}

 .jut__RadioButton__radioButton {
    --radio-button-icon-focus-box-shadow: 0 0 0px 2px white, 0 0 0px 4px #292929 !important;
}

.jut__CheckboxField__checkboxElement:focus {
     border: 1px solid #1e9acf !important;
   --checkbox-focus-box-shadow: 0 0 0px 2px white, 0 0 0px 4px #292929 !important;
}

.jut__RadioButtonCardField__radioWrapperPortrait {
    width: var(--radio-button-card-icon-size-landscape) !important;
}

.jut__FileUploadField__valueText {
    @media (max-width: 395px) {
        width: 175px !important;
    }
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell:not(.jut__DataTable__actionColumn):not(.jut__DataTable__expanderCell):not(.jut__DataTable__editedCell):not(.jut__DataTable__selectionCell) > * {
    @media (max-width: 395px) {
        min-width: min-content;
    }
}

.actionsContainer-root .actionsContainer .jut__Button__secondary{
    @media (max-width: 359px) {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 160px !important;
        margin-right: 10px !important;
    }
    
}

.actionsContainer-root .actionsContainer .jut__Button__primary{
    @media (max-width: 359px) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px !important;
        max-width: 140px !important;
    }
}


.skipLeftNav{
  color: #0D73B8;
  font-weight: 600;
  text-decoration: none !important;
  position: relative;
  top: auto;
  overflow: hidden;
  z-index: -999;
  width: auto;
  height: 0px;
  &:focus-visible{
    height: 20px;
    left: 0;
    z-index: 100;
  }
  padding-left: 5px;
}


[class*=twoInputContainer] {
    max-width: 460px;
}

[class*=twoInputContainer] > div {
    margin-bottom: var(--GW-SPACING) !important;
}

.btnLink {
    text-decoration: none !important;
}
.buttonlinkpadding :hover span{
    text-decoration: underline !important;
}

.fieldSetDob {
  border-style: hidden;
  padding: 0;
}

// Accessibility defect DI-363 ,DI-440 The top options in ALL drop-down elements for START and END dates:
// are not visible on the screen for keyboard users at the viewport width of 320px.
#dropdownDobDate-wrapper div.jut__GenericSelectControl__option, #dropdownStartDate-wrapper div.jut__GenericSelectControl__option, 
#dropdownInjuryOneTimeDate-wrapper div.jut__GenericSelectControl__option, #dropdownInjuryOneTimeHours-wrapper div.jut__GenericSelectControl__option,
#dropdownInjuryDate-wrapper div.jut__GenericSelectControl__option, #dropdownInjuryHours-wrapper div.jut__GenericSelectControl__option, 
#dropdownHealthCareReceivedDate-wrapper div.jut__GenericSelectControl__option, #dropdownEmployerReportedDate-wrapper div.jut__GenericSelectControl__option,
#dropdownMissedDate-wrapper div.jut__GenericSelectControl__option, #dropdownModifiedDate-wrapper div.jut__GenericSelectControl__option, 
#dropdownReturnedDate-wrapper div.jut__GenericSelectControl__option, #dropdownReturningDate-wrapper div.jut__GenericSelectControl__option,
#dropdownEndDate-wrapper div.jut__GenericSelectControl__option, #dropdownExpectedDate-wrapper div.jut__GenericSelectControl__option, 
#dropdownInjuryStartDate-wrapper div.jut__GenericSelectControl__option, #dropdownMentalStartDate-wrapper div.jut__GenericSelectControl__option,
#dropdownMentalEndDate-wrapper div.jut__GenericSelectControl__option, #dropdownExposureStartDate-wrapper div.jut__GenericSelectControl__option, 
#dropdownExposureDate-wrapper div.jut__GenericSelectControl__option,#dropdownDobMonth-wrapper div.jut__GenericSelectControl__option, 
#dropdownStartMonth-wrapper div.jut__GenericSelectControl__option, #dropdownInjuryOneTimeMonth-wrapper div.jut__GenericSelectControl__option, 
#dropdownInjuryOneTimeMinute-wrapper div.jut__GenericSelectControl__option,#dropdownInjuryMonth-wrapper div.jut__GenericSelectControl__option,
#dropdownInjuryMinute-wrapper div.jut__GenericSelectControl__option, #dropdownHealthCareReceivedMonth-wrapper div.jut__GenericSelectControl__option,
#dropdownEmployerReportedMonth-wrapper div.jut__GenericSelectControl__option,#dropdownMissedMonth-wrapper div.jut__GenericSelectControl__option,
#dropdownModifiedMonth-wrapper div.jut__GenericSelectControl__option, #dropdownReturnedMonth-wrapper div.jut__GenericSelectControl__option, #dropdownReturningMonth-wrapper,
#dropdownEndMonth-wrapper, #dropdownExpectedMonth-wrapper, #dropdownInjuryStartMonth-wrapper div.jut__GenericSelectControl__option, 
#dropdownMentalStartMonth-wrapper div.jut__GenericSelectControl__option,#dropdownMentalEndMonth-wrapper div.jut__GenericSelectControl__option, 
#dropdownExposureStartMonth-wrapper div.jut__GenericSelectControl__option, #dropdownExposureMonth-wrapper div.jut__GenericSelectControl__option,
#dropdownDobYear-wrapper div.jut__GenericSelectControl__option, #dropdownStartYear-wrapper div.jut__GenericSelectControl__option, 
#dropdownInjuryOneTimeYear-wrapper div.jut__GenericSelectControl__option, #dropdownInjuryYear-wrapper div.jut__GenericSelectControl__option,
#dropdownHealthCareReceivedYear-wrapper div.jut__GenericSelectControl__option, #dropdownEmployerReportedYear-wrapper div.jut__GenericSelectControl__option,
#dropdownMissedYear-wrapper div.jut__GenericSelectControl__option, #dropdownModifiedYear-wrapper div.jut__GenericSelectControl__option, 
#dropdownReturnedYear-wrapper div.jut__GenericSelectControl__option, #dropdownReturningYear-wrapper div.jut__GenericSelectControl__option,
#dropdownEndYear-wrapper div.jut__GenericSelectControl__option, #dropdownExpectedYear-wrapper div.jut__GenericSelectControl__option, 
#dropdownInjuryStartYear-wrapper div.jut__GenericSelectControl__option, #dropdownMentalStartYear-wrapper div.jut__GenericSelectControl__option,
#dropdownMentalEndYear-wrapper div.jut__GenericSelectControl__option, #dropdownExposureStartYear-wrapper div.jut__GenericSelectControl__option, 
#dropdownExposureYear-wrapper div.jut__GenericSelectControl__option {
    @media (max-width: 395px) {
        font-size: 95px !important;
        width: fit-content;
        padding: 0px;
    }
}

#payFrequency-wrapper div.jut__GenericSelectControl__option  {
    @media (max-width: 395px) {
        font-size: 12px !important;
        width: fit-content;
        padding: 0px;
    }
}