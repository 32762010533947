.jut__ActionColumn__themeRoot {
    --GW-BREAKPOINT-PHONE: 580px;
    --GW-BREAKPOINT-TABLET: 1024px
}

.jut__ActionColumn__actionButton {
    --action-button-color: var(--GW-COLOR-TEXT-2);
    --action-button-hover-bg-color: var(--GW-COLOR-HIGHLIGHT-1-HOVER);
    --action-button-active-bg-color: var(--GW-COLOR-HIGHLIGHT-1-ACTIVE);
    --action-button-size: var(--GW-SPACING-XL);
    --action-button-margin-left: var(--GW-SPACING);
    --button-height-sm: var(--action-button-size);
    --button-tertiary-color: var(--action-button-color);
    --button-tertiary-hover-color: var(--action-button-color);
    --button-tertiary-hover-bg-color: var(--action-button-hover-bg-color);
    --button-tertiary-active-bg-color: var(--action-button-active-bg-color);
    --button-padding-x: 0;
    --button-icon-margin-x: 0;
    --button-padding-y: 0;
    --button-icon-margin-y: 0;
    width: var(--action-button-size);
    margin-left: var(--action-button-margin-left);
    opacity: 0
}

.jut__ActionColumn__actionButton:focus {
    opacity: 1
}

.ReactTable.-highlight .rt-tbody div.rt-tr-group:not(.-padRow):hover .jut__ActionColumn__actionButton {
    opacity: 1
}

@media (max-width: 1024px) {
    .jut__ActionColumn__actionButton {
        opacity: 1
    }
}

.jut__ActionColumn__dropdownMenu {
    --action-button-menu-margin-top: calc(0.5 * var(--GW-SPACING-XXS));
    --action-button-menu-label-margin-right: 10px;
    --action-button-menu-font-size: var(--GW-FONT-SIZE-SM);
    --action-button-menu-line-height: var(--GW-LINE-HEIGHT-SM);
    position: absolute
}

.jut__ActionColumn__dropdownMenu .jut__ActionColumn__menu {
    font-size: var(--action-button-menu-font-size);
    line-height: var(--action-button-menu-line-height);
    margin-top: var(--action-button-menu-margin-top)
}

.jut__ActionColumn__dropdownMenu .jut__ActionColumn__menu .jut__ActionColumn__menuItemLabel {
    margin-left: var(--action-button-menu-label-margin-right)
}

.jut__ActionColumn__editButton {
    --edit-button-size: var(--GW-SPACING-XXL);
    width: var(--edit-button-size);
    height: var(--edit-button-size)
}

.jut__ActionColumn__actionColumnCell {
    position: relative;
    justify-content: flex-end
}

.jut__ActionColumn__cancelButton {
    --cancel-button-text-color: var(--GW-COLOR-TEXT-2);
    --cancel-button-hover-color: var(--GW-COLOR-HIGHLIGHT-1-HOVER);
    --cancel-button-active-color: var(--GW-COLOR-HIGHLIGHT-1-ACTIVE);
    --button-tertiary-color: var(--cancel-button-text-color);
    --button-tertiary-hover-color: var(--cancel-button-text-color);
    --button-tertiary-active-color: var(--cancel-button-text-color);
    --button-tertiary-active-bg-color: var(--cancel-button-active-color);
    --button-tertiary-hover-bg-color: var(--cancel-button-hover-color)
}

.jut__ActionColumn__focusedButton {
    opacity: 1
}

.jut__DataTable__themeRoot {
    --GW-BREAKPOINT-PHONE: 580px;
    --GW-BREAKPOINT-TABLET: 1024px
}

.jut__DataTable__dataTable {
    --data-table-font-family: var(--GW-FONT-FAMILY);
    --data-table-line-height: var(--GW-LINE-HEIGHT-SM);
    --data-table-font-size: var(--GW-FONT-SIZE-SM);
    --data-table-font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    --data-table-color: var(--GW-COLOR-TEXT-1);
    --data-table-clickable-cursor: pointer;
    --data-table-border-radius: var(--GW-BORDER-RADIUS);
    --data-table-bg-color: var(--GW-COLOR-BACKGROUND-1);
    --data-table-header-cell-focus-box-shadow: var(--GW-BOX-SHADOW-FOCUS);
    --data-table-header-cell-sorted-color: var(--GW-COLOR-BRAND-1);
    --data-table-cell-padding: var(--GW-SPACING-XS);
    --data-table-first-cell-padding-left: var(--GW-SPACING);
    --data-table-last-cell-padding-right: var(--GW-SPACING);
    --data-table-selection-cell-padding-left: var(--GW-SPACING);
    --data-table-selection-cell-padding-right: var(--GW-SPACING-XXS);
    --data-table-selection-cell-padding-right-service: var(--GW-SPACING-SM);
    --data-table-row-bg-color: var(--data-table-bg-color);
    --data-table-row-bg-color-hover: var(--GW-COLOR-HIGHLIGHT-1);
    --data-table-row-bg-color-active: var(--GW-COLOR-HIGHLIGHT-1-HOVER);
    --data-table-row-bg-color-odd: var(--data-table-bg-color);
    --data-table-row-bg-color-even: var(--GW-COLOR-BACKGROUND-2);
    --data-table-row-expandable-bg-color: var(--data-table-bg-color);
    --data-table-chevron-font-size: var(--GW-FONT-SIZE);
    --data-table-chevron-font-family: "Material Icons Round";
    --data-table-chevron-font-weight: var(--GW-FONT-WEIGHT-BLACK);
    --data-table-chevron-margin: var(--GW-SPACING-XXS);
    --data-table-chevron-not-sorted-color: var(--GW-COLOR-BORDER);
    --data-table-body-border: solid var(--GW-BORDER-WIDTH-THIN) var(--GW-COLOR-STROKE);
    --data-table-bar-padding-x: var(--GW-SPACING);
    --data-table-bar-padding-y: var(--GW-SPACING-XL);
    --data-table-bar-bg-color: var(--GW-COLOR-BACKGROUND-2);
    --data-table-search-max-width: 320px;
    --data-table-search-icon-height: 2rem;
    --data-table-search-padding: 0.375rem;
    --data-table-search-height: 2rem;
    --data-table-search-bg-color-focus: var(--GW-COLOR-BACKGROUND-1);
    --data-table-cell-spacing-x: var(--GW-SPACING-SM);
    --data-table-cell-spacing-y: var(--GW-SPACING-XXS);
    --data-table-cell-height: var(--GW-SPACING-XXL);
    --data-table-expander-icon-width: var(--GW-FONT-SIZE);
    --data-table-expander-icon-font-size: var(--GW-FONT-SIZE);
    --data-table-expander-icon-spacing-x: var(--GW-SPACING-XXS);
    --data-table-expander-icon-opacity-disabled: var(--GW-DISABLED-OPACITY);
    --data-table-expander-icon-box-shadow: var(--GW-BOX-SHADOW);
    --data-table-expander-icon-border-color-focused: var(--GW-COLOR-FOCUS-1);
    --data-table-expander-icon-border-radius: var(--GW-BORDER-RADIUS);
    --data-table-expander-icon-border: var(--GW-BORDER-WIDTH-THIN) solid transparent;
    --data-table-expandable-content-padding-x: var(--GW-SPACING-XL);
    --data-table-expandable-content-padding-y: var(--GW-SPACING);
    --data-table-selection-action-button-spacing: var(--GW-SPACING-SM);
    --data-table-selection-action-button-border: none;
    --data-table-selection-action-button-padding-right: 0;
    --data-table-actions-margin-left: var(--GW-SPACING);
    --data-table-selection-radio-width: 16px;
    --data-table-no-data-font-color: var(--GW-COLOR-TEXT-2);
    --data-table-no-data-font-size: var(--GW-FONT-SIZE-SM);
    --data-table-no-data-line-height: var(--GW-LINE-HEIGHT-SM);
    --data-table-no-data-padding-top: var(--GW-SPACING-XL);
    --data-table-no-data-padding-bottom: var(--GW-SPACING-XL);
    background-color: var(--data-table-bg-color);
    font-family: var(--data-table-font-family);
    font-size: var(--data-table-font-size);
    line-height: var(--data-table-line-height);
    font-weight: var(--data-table-font-weight);
    color: var(--data-table-color);
    border-radius: var(--data-table-border-radius)
}

.dark .jut__DataTable__dataTable {
    --data-table-header-cell-sorted-color: var(--GW-COLOR-BRAND-3)
}

.ReactTable div.rt-thead.-header {
    box-shadow: none
}

.ReactTable div.rt-thead.-header .jut__DataTable__unsortedHeaderCell {
    padding: 0
}

.ReactTable div.rt-resizable-header {
    outline: none
}

.ReactTable div.rt-resizable-header:not(.jut__DataTable__unsortedHeaderCell):focus {
    box-shadow: inset var(--data-table-header-cell-focus-box-shadow)
}

.ReactTable.-highlight .rt-tbody div.rt-tr:not(.-padRow) {
    background-color: var(--data-table-row-bg-color)
}

.ReactTable.-highlight .rt-tbody div.rt-tr:not(.-padRow):hover {
    background-color: var(--data-table-row-bg-color-hover)
}

.ReactTable.-highlight .rt-tbody div.rt-tr:not(.-padRow).jut__DataTable__clickableRow:active, .ReactTable.-highlight .rt-tbody div.rt-tr:not(.-padRow).jut__DataTable__activeRow {
    background-color: var(--data-table-row-bg-color-active)
}

.ReactTable.-highlight .rt-tbody div.rt-tr:not(.-padRow).jut__DataTable__clickableRow:focus {
    outline: none;
    box-shadow: inset var(--data-table-header-cell-focus-box-shadow)
}

.ReactTable.-highlight .rt-tbody div.rt-tr:not(.-padRow).jut__DataTable__selectedRow {
    --data-table-row-bg-color: var(--GW-COLOR-HIGHLIGHT-1)
}

.ReactTable div.rt-td, .ReactTable .rt-thead div.rt-th {
    padding: var(--data-table-cell-padding);
    white-space: initial
}

.ReactTable .rt-thead div.rt-th {
    line-height: var(--data-table-line-height);
    font-weight: var(--GW-FONT-WEIGHT-BOLD)
}

.ReactTable.-striped .rt-tbody div.rt-tr.-odd {
    --data-table-row-bg-color: var(--data-table-row-bg-color-odd)
}

.ReactTable.-striped .rt-tbody div.rt-tr.-even {
    --data-table-row-bg-color: var(--data-table-row-bg-color-even)
}

.ReactTable .rt-thead div.rt-tr {
    text-align: left
}

.ReactTable .rt-thead div.rt-th.-cursor-pointer::after {
    content: "";
    font-family: var(--data-table-chevron-font-family);
    font-weight: var(--data-table-chevron-font-weight);
    font-size: var(--data-table-chevron-font-size);
    margin-left: var(--data-table-chevron-margin);
    color: var(--data-table-chevron-not-sorted-color)
}

.ReactTable .rt-thead div.rt-th.-sort-desc, .ReactTable .rt-thead div.rt-th.-sort-asc {
    color: var(--data-table-header-cell-sorted-color);
    box-shadow: none
}

.ReactTable .rt-thead div.rt-th.-sort-desc:focus, .ReactTable .rt-thead div.rt-th.-sort-asc:focus {
    box-shadow: inset var(--data-table-header-cell-focus-box-shadow)
}

.ReactTable .rt-thead div.rt-th.-sort-desc::after, .ReactTable .rt-thead div.rt-th.-sort-asc::after {
    font-family: var(--data-table-chevron-font-family);
    font-weight: var(--data-table-chevron-font-weight);
    font-size: var(--data-table-chevron-font-size);
    margin-left: var(--data-table-chevron-margin);
    color: var(--data-table-header-cell-sorted-color)
}

.ReactTable .rt-thead div.rt-th.-sort-desc::after {
    content: ""
}

.ReactTable .rt-thead div.rt-th.-sort-asc::after {
    content: ""
}

.jut__DataTable__dataTable .jut__DataTable__reactTable {
    border: none
}

.jut__DataTable__reactTable div.rt-table {
    overflow: unset
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader {
    border-bottom: var(--data-table-body-border)
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody {
    overflow: unset;
    border-bottom: var(--data-table-body-border)
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRowGroup {
    border-bottom: none
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell {
    border-right: none;
    display: flex;
    align-items: center;
    padding: var(--data-table-cell-spacing-y) var(--data-table-cell-spacing-x);
    min-height: var(--data-table-cell-height);
    line-height: var(--data-table-cell-height);
    transition-property: width, min-width, opacity
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__headerOneLine, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__headerOneLine {
    white-space: nowrap
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__editedCell, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__editedCell {
    line-height: unset;
    min-height: unset;
    overflow: visible
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__editedCell:not(.jut__DataTable__actionColumn), .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__editedCell:not(.jut__DataTable__actionColumn) {
    padding-left: 0;
    padding-right: calc(2 * var(--data-table-cell-spacing-x))
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__editedCell:not(.jut__DataTable__actionColumn).jut__DataTable__rightAlignCell, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__editedCell:not(.jut__DataTable__actionColumn).jut__DataTable__rightAlignCell {
    padding-left: calc(2 * var(--data-table-cell-spacing-x));
    padding-right: 0
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__editedCell:first-child, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__editedCell:first-child {
    padding-left: calc(var(--data-table-first-cell-padding-left) - var(--data-table-cell-spacing-x))
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__editedCell:last-child, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__editedCell:last-child {
    padding-right: calc(var(--data-table-last-cell-padding-right) - var(--data-table-cell-spacing-x))
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__selectionCell, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__selectionCell {
    padding-left: var(--data-table-selection-cell-padding-left);
    padding-right: var(--data-table-selection-cell-padding-right)
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__selectionCell.jut__DataTable__serviceCell, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__selectionCell.jut__DataTable__serviceCell {
    padding-right: var(--data-table-selection-cell-padding-right-service)
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell.jut__DataTable__expanderCell.jut__DataTable__serviceCell, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell.jut__DataTable__expanderCell.jut__DataTable__serviceCell {
    padding-left: 0;
    padding-right: 0
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell {
    position: static
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .jut__DataTable__tableCell:not(.jut__DataTable__actionColumn):not(.jut__DataTable__expanderCell):not(.jut__DataTable__editedCell):not(.jut__DataTable__selectionCell) > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell {
    position: relative
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .rt-expandable {
    display: flex;
    align-items: center;
    padding-left: var(--data-table-expander-icon-spacing-x);
    padding-right: var(--data-table-expander-icon-spacing-x)
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .rt-expandable.disabled {
    cursor: default
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableRow .rt-expandable.disabled .jut__DataTable__expanderIcon {
    opacity: var(--data-table-expander-icon-opacity-disabled)
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell:not(.jut__DataTable__selectionCell):first-child, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableCell:not(.jut__DataTable__selectionCell):first-child {
    padding-left: var(--data-table-first-cell-padding-left)
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell:focus {
    outline: none
}

.jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableHeader .jut__DataTable__tableHeaderCell:last-child, .jut__DataTable__dataTable .jut__DataTable__reactTable .jut__DataTable__tableBody .jut__DataTable__tableCell:last-child {
    padding-right: var(--data-table-last-cell-padding-right)
}

.jut__DataTable__tableBar {
    display: flex;
    justify-content: space-between;
    padding: var(--data-table-bar-padding-y) var(--data-table-bar-padding-x);
    background-color: var(--data-table-bar-bg-color)
}

.jut__DataTable__selectionActions {
    border-right: var(--data-table-selection-action-button-border);
    padding-right: var(--data-table-selection-action-button-padding-right)
}

.jut__DataTable__selectionActions.jut__DataTable__transition {
    overflow: hidden
}

.jut__DataTable__selectionActions .jut__DataTable__buttons {
    position: relative;
    left: 100%;
    transition: left .4s ease
}

.jut__DataTable__selectionActions .jut__DataTable__buttons.jut__DataTable__enter {
    left: 0
}

.jut__DataTable__selectionActions .jut__DataTable__buttons > * {
    margin-left: var(--data-table-selection-action-button-spacing)
}

.jut__DataTable__selectionActions .jut__DataTable__buttons > *:first-child {
    margin-left: 0
}

.jut__DataTable__withBorder {
    --data-table-selection-action-button-border: 1px solid var(--GW-COLOR-STROKE);
    --data-table-selection-action-button-padding-right: var(--GW-SPACING)
}

.jut__DataTable__columnsConfigAction {
    margin-left: var(--data-table-actions-margin-left)
}

.jut__DataTable__searchWrapper {
    --field-component-icon-height: var(--data-table-search-icon-height);
    max-width: var(--data-table-search-max-width);
    flex: 1
}

.jut__DataTable__search {
    --input-padding-y: var(--data-table-search-padding);
    --input-height: var(--data-table-search-height);
    --input-font-size: var(--GW-FONT-SIZE-SM)
}

.jut__DataTable__search:focus {
    background-color: var(--data-table-search-bg-color-focus)
}

.jut__DataTable__expanderIcon {
    --chevron-size-x: var(--data-table-expander-icon-width);
    top: 0;
    font-size: var(--data-table-expander-icon-font-size);
    display: flex;
    place-items: center;
    place-content: center;
    border: var(--data-table-expander-icon-border);
    border-radius: var(--data-table-expander-icon-border-radius)
}

.jut__DataTable__expanderIcon:focus {
    outline: none;
    box-shadow: var(--data-table-expander-icon-box-shadow);
    border-color: var(--data-table-expander-icon-border-color-focused)
}

.jut__DataTable__expandableRowWrapper {
    font-size: var(--data-table-font-size);
    padding: var(--data-table-expandable-content-padding-y) var(--data-table-expandable-content-padding-x)
}

.jut__DataTable__expandableRow {
    background-color: var(--data-table-row-expandable-bg-color)
}

.jut__DataTable__noDataMessage {
    padding-top: var(--data-table-no-data-padding-top);
    padding-bottom: var(--data-table-no-data-padding-bottom);
    font-size: var(--data-table-no-data-font-size);
    line-height: var(--data-table-no-data-line-height);
    color: var(--data-table-no-data-font-color);
    text-align: center;
    border-bottom: var(--data-table-body-border);
    background-color: var(--data-table-bg-color)
}

.jut__DataTable__clickableRow {
    cursor: var(--data-table-clickable-cursor)
}

.jut__DataTable__radio {
    width: var(--data-table-selection-radio-width);
    line-height: 0
}

.jut__DataTable__headerMultiline { /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    box-orient: vertical;
    display: -webkit-box;
    display: box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2
}

.jut__DataTable__actionPanel {
    display: flex
}

.jut__FieldColumn__fullWidth {
    width: 100%
}

.jut__Pagination__pagination {
    --pagination-button-font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    --pagination-button-selected-font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    --pagination-button-selected-bg-color: var(--GW-COLOR-HIGHLIGHT-1);
    --pagination-button-font-color: var(--GW-COLOR-TEXT-1);
    --pagination-button-background-color: var(--GW-COLOR-HIGHLIGHT-1-HOVER);
    --pagination-font-size: var(--GW-FONT-SIZE-SM);
    --pagination-padding: var(--GW-SPACING);
    --pagination-message-padding-x: var(--GW-SPACING-XS);
    --pagination-nav-button-focus-box-shadow: var(--GW-BOX-SHADOW-FOCUS);
    --pagination-select-focus-box-shadow: var(--GW-BOX-SHADOW-FOCUS);
    --pagination-select-border-radius: var(--GW-BORDER-RADIUS);
    --pagination-select-padding-x: var(--GW-SPACING-SM);
    --pagination-select-menu-min-width: var(--GW-SPACING-SM);
    --pagination-select-background-color: var(--GW-COLOR-BACKGROUND-3);
    --pagination-select-hover-background-color: var(--GW-COLOR-BACKGROUND-3-HOVER);
    --pagination-select-active-background-color: var(--GW-COLOR-BACKGROUND-3-ACTIVE);
    --pagination-dropdown-font-size: var(--GW-FONT-SIZE-SM);
    --pagination-dropdown-line-height: var(--GW-LINE-HEIGHT-SM);
    display: flex;
    justify-content: space-between;
    padding: var(--pagination-padding);
}

.dark .jut__Pagination__pagination {
    --pagination-select-background-color: var(--GW-COLOR-BACKGROUND-2);
    --pagination-select-hover-background-color: var(--GW-COLOR-BACKGROUND-2-HOVER);
    --pagination-select-active-background-color: var(--GW-COLOR-BACKGROUND-2-ACTIVE);
    --pagination-pagesize-select-background-color: var(--GW-COLOR-BACKGROUND-2)
}

.jut__Pagination__paginationNav {
    display: flex
}

.jut__Pagination__paginationNavButton {
    color: var(--pagination-button-font-color);
    font-weight: var(--pagination-button-font-weight);
    font-size: var(--pagination-font-size)
}

.jut__Pagination__paginationNavButton:hover {
    color: var(--pagination-button-font-color);
    background-color: var(--pagination-button-selected-bg-color)
}

.jut__Pagination__paginationNavButton:active, .jut__Pagination__paginationNavButton:active:focus, .jut__Pagination__paginationNavButton:active:focus:hover, .jut__Pagination__paginationNavButton.active {
    background-color: var(--pagination-button-background-color);
    color: var(--pagination-button-font-color)
}

.jut__Pagination__paginationNavButton:focus {
    color: var(--pagination-button-font-color);
    box-shadow: var(--pagination-nav-button-focus-box-shadow)
}

.jut__Pagination__paginationNavButton:hover:focus {
    color: var(--pagination-button-font-color);
    box-shadow: var(--pagination-nav-button-focus-box-shadow)
}

.jut__Pagination__paginationNavButton span i {
    margin: 0
}

.jut__Pagination__pageButtonsWrapper {
    display: flex
}

.jut__Pagination__selectedPageButton {
    font-weight: var(--pagination-button-selected-font-weight);
    background-color: var(--pagination-button-selected-bg-color);
    font-size: var(--pagination-font-size)
}

.jut__Pagination__resultOfMessage {
    padding-left: var(--pagination-message-padding-x)
}

.jut__Pagination__pageSizeSelectWrapper {
    display: flex;
    justify-content: center;
    align-items: center
}

.jut__Pagination__paginationSelect + .jut__Pagination__paginationMenu {
    min-width: var(--pagination-select-menu-min-width)
}

.jut__Pagination__paginationMenu .jut__Pagination__paginationMenuItem {
    line-height: var(--pagination-dropdown-line-height);
    font-size: var(--pagination-dropdown-font-size);
    padding-left: var(--pagination-select-padding-x);
    padding-right: var(--pagination-select-padding-x)
}

.jut__Pagination__paginationSelect {
    border-radius: var(--pagination-select-border-radius);
    color: var(--pagination-button-font-color);
    font-weight: var(--pagination-button-font-weight);
    background-color: var(--pagination-select-background-color);
    font-size: var(--pagination-font-size)
}

.jut__Pagination__paginationSelect:hover {
    color: var(--pagination-button-font-color);
    background-color: var(--pagination-select-hover-background-color)
}

.jut__Pagination__paginationSelect:active, .jut__Pagination__paginationSelect:active:focus, .jut__Pagination__paginationSelect:active:focus:hover, .jut__Pagination__paginationSelect.active {
    background-color: var(--pagination-select-active-background-color);
    color: var(--pagination-button-font-color)
}

.jut__Pagination__paginationSelect:focus {
    color: var(--pagination-button-font-color);
    box-shadow: var(--pagination-select-focus-box-shadow);
    background-color: var(--pagination-select-background-color)
}

.jut__Pagination__paginationSelect:hover:focus {
    color: var(--pagination-button-font-color);
    box-shadow: var(--pagination-select-focus-box-shadow);
    background-color: var(--pagination-select-hover-background-color)
}

.jut__ColumnEntry__themeRoot {
    --GW-BREAKPOINT-PHONE: 580px;
    --GW-BREAKPOINT-TABLET: 1024px
}

.jut__ColumnEntry__columnEntry {
    --column-entry-width: 472px;
    --column-entry-height: var(--GW-SPACING-XXL);
    --column-entry-bg-color: var(--GW-COLOR-BACKGROUND-3);
    --column-entry-bg-color-hover: var(--GW-COLOR-BACKGROUND-3-HOVER);
    --column-entry-bg-color-active: var(--GW-COLOR-BACKGROUND-3-ACTIVE);
    --column-entry-padding-x: var(--GW-SPACING-SM);
    --column-entry-padding-x: var(--GW-SPACING-SM);
    --column-entry-transition-speed: var(--GW-ANIMATION-SPEED);
    --column-entry-border-width: var(--GW-BORDER-WIDTH-THIN);
    --column-entry-border-color: transparent;
    --column-entry-border-color-focus: var(--GW-COLOR-FOCUS-1-HOVER);
    --column-entry-border-radius: var(--GW-SPACING-XXS);
    --column-entry-box-shadow-focus: var(--GW-BOX-SHADOW-FOCUS);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--column-entry-width);
    height: var(--column-entry-height);
    background-color: var(--column-entry-bg-color);
    border: var(--column-entry-border-width) solid var(--column-entry-border-color);
    border-radius: var(--column-entry-border-radius);
    padding-left: var(--column-entry-padding-x);
    padding-right: var(--column-entry-padding-x);
    transition: background-color var(--column-entry-transition-speed)
}

.jut__ColumnEntry__columnEntry:hover {
    background-color: var(--column-entry-bg-color-hover)
}

.jut__ColumnEntry__columnEntryCheckbox {
    align-items: center
}

.jut__ColumnEntry__columnEntryLabel { /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    box-orient: vertical;
    display: -webkit-box;
    display: box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    cursor: pointer
}

.jut__Table__themeRoot {
    --GW-BREAKPOINT-PHONE: 580px;
    --GW-BREAKPOINT-TABLET: 1024px
}

.jut__Table__table {
    --table-bg-color: var(--GW-COLOR-BACKGROUND-1);
    --table-border-color: var(--GW-COLOR-BORDER);
    --table-color: var(--GW-COLOR-TEXT-1);
    --table-font-family: var(--GW-FONT-FAMILY);
    --table-padding-x: var(--GW-SPACING);
    --table-padding-y: var(--GW-SPACING-SM);
    --table-header-font-size: var(--GW-FONT-SIZE-SM);
    --table-header-font-weight: var(--GW-FONT-WEIGHT-BOLD);
    --table-header-line-height: var(--GW-LINE-HEIGHT);
    --table-header-border-width: var(--GW-BORDER-WIDTH-THIN);
    --table-header-bg-color: var(--GW-COLOR-BACKGROUND-1);
    --table-cell-font-size: var(--GW-FONT-SIZE);
    --table-cell-font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    --table-cell-border-width: 0;
    --table-cell-line-height: var(--GW-SPACING-XL);
    --table-row-hover-color: var(--GW-COLOR-TEXT-1);
    --table-row-hover-bg-color: var(--GW-COLOR-HIGHLIGHT-1);
    --table-row-stripe-bg-color: var(--GW-COLOR-BACKGROUND-2);
    --table-placeholder-line-height: var(--GW-LINE-HEIGHT);
    --table-placeholder-font-size: var(--GW-FONT-SIZE);
    --table-placeholder-color: var(--GW-COLOR-TEXT-2);
    --table-placeholder-bg-color: var(--GW-COLOR-BACKGROUND-1);
    --table-placeholder-padding-x: var(--GW-SPACING);
    --table-placeholder-padding-y: var(--GW-SPACING-XL);
    --card-color: var(--GW-COLOR-TEXT-1);
    --card-font-family: var(--GW-FONT-FAMILY);
    --card-bg-color: var(--GW-COLOR-BACKGROUND-1);
    --card-bg-color-even: var(--GW-COLOR-BACKGROUND-2);
    --table-placeholder-padding-y: var(--GW-SPACING);
    --card-header-font-size: var(--GW-FONT-SIZE-SM);
    --card-header-line-height: var(--GW-LINE-HEIGHT);
    --card-header-font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    --card-cell-font-size: var(--GW-FONT-SIZE-SM);
    --card-cell-font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    --card-cell-line-height: var(--table-header-line-height);
    --radio-card-row-padding: var(--GW-SPACING-XXL);
    --hoverable-title-padding: calc(1.75 * var(--GW-SPACING-XL));
    background-color: var(--table-bg-color);
    padding: var(--table-padding-y) var(--table-padding-x) var(--table-padding-y) var(--table-padding-x)
}

.jut__Table__table .jut__Table__radio {
    --radio-button-field-margin: 0;
    --radio-button-field-margin-left: var(--GW-SPACING-XS);
    --radio-button-bg-color: var(--table-bg-color);
    --radio-button-label-padding: calc(1.25 * var(--GW-SPACING-XL))
}

.jut__Table__tableElement {
    width: 100%;
    background-color: var(--table-bg-color);
    border-collapse: collapse;
    font-family: var(--table-font-family);
    color: var(--table-color);
    vertical-align: top
}

.jut__Table__tableHeader {
    background-color: var(--table-header-bg-color);
    font-size: var(--table-header-font-size);
    font-weight: var(--table-header-font-weight);
    line-height: var(--table-header-line-height);
    border-bottom: solid var(--table-header-border-width) var(--table-border-color);
    padding: var(--table-padding-y) var(--table-padding-x)
}

.jut__Table__tableCell {
    font-size: var(--table-cell-font-size);
    line-height: var(--table-cell-line-height);
    border-bottom: solid var(--table-cell-border-width) var(--table-border-color);
    padding: var(--table-padding-y) var(--table-padding-x);
    vertical-align: top
}

.jut__Table__hoverable {
    cursor: pointer
}

.jut__Table__hoverable thead .jut__Table__row th:first-child {
    padding-left: var(--hoverable-title-padding)
}

.jut__Table__hoverable .jut__Table__row:hover {
    color: var(--table-row-hover-color);
    background-color: var(--table-row-hover-bg-color)
}

.jut__Table__hoverable .jut__Table__card:hover {
    background-color: var(--table-row-hover-bg-color)
}

.jut__Table__radioRow .jut__Table__tableCardElementHeader {
    display: none
}

.jut__Table__radioRow .jut__Table__tableCardElementContent {
    width: 100%;
    text-align: left;
    font-weight: var(--card-header-font-weight)
}

.jut__Table__radioRow ~ .jut__Table__tableCardRow .jut__Table__tableCardElementHeader {
    padding-left: var(--radio-card-row-padding)
}

.jut__Table__card {
    --card-padding-y: var(--GW-SPACING-XS);
    --card-padding-x: var(--GW-SPACING-XS);
    --card-border: none;
    --card-padding-bottom: var(--GW-SPACING-XS)
}

.jut__Table__card .jut__Table__radio {
    --radio-button-label-padding: var(--GW-SPACING-XL)
}

.jut__Table__card:nth-child(odd) {
    background-color: var(--table-row-stripe-bg-color)
}

.jut__Table__placeholder {
    text-align: center;
    padding: var(--table-placeholder-padding-y) var(--table-placeholder-padding-x);
    color: var(--table-placeholder-color);
    background-color: var(--table-placeholder-bg-color);
    font-family: var(--table-font-family);
    line-height: var(--table-placeholder-line-height);
    font-size: var(--table-placeholder-font-size)
}

.jut__Table__tableCard {
    width: 100%
}

.jut__Table__tableCardRow > td {
    width: 50%;
    padding: 4px var(--card-padding-x);
    vertical-align: top
}

.jut__Table__tableCardElementHeader {
    text-align: left;
    font-size: var(--card-header-font-size);
    font-weight: var(--card-header-font-weight);
    line-height: var(--card-header-line-height)
}

.jut__Table__tableCardElementContent {
    text-align: right;
    font-size: var(--card-cell-font-size);
    font-weight: var(--card-cell-font-weight);
    line-height: var(--card-cell-line-height)
}

.jut__TableTitleBar__tableTitleBar {
    --table-title-bar-bg-color: var(--GW-COLOR-BACKGROUND-1);
    --table-title-bar-border-color: var(--GW-COLOR-BORDER);
    --table-title-bar-border-width: var(--GW-BORDER-WIDTH);
    --table-title-bar-font-size: var(--GW-FONT-SIZE-MD);
    --table-title-bar-font-family: var(--GW-FONT-FAMILY);
    --table-title-bar-line-height: var(--GW-LINE-HEIGHT);
    --table-title-bar-font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    --table-title-bar-padding-x: 0;
    --table-title-bar-padding-y: var(--GW-SPACING-XS);
    --table-title-color: var(--GW-COLOR-TEXT-1);
    --table-title-action-margin-x: var(--GW-SPACING-XS);
    border-bottom: var(--table-title-bar-border-width) solid var(--table-title-bar-border-color);
    display: flex;
    align-items: center;
    padding: var(--table-title-bar-padding-y) var(--table-title-bar-padding-x);
    background-color: var(--table-title-bar-bg-color)
}

.jut__TableTitleBar__title {
    color: var(--table-title-color);
    font-size: var(--table-title-bar-font-size);
    font-family: var(--table-title-bar-font-family);
    font-weight: var(--table-title-bar-font-weight);
    line-height: var(--table-title-bar-line-height);
    flex: 1
}

.jut__TableTitleBar__left {
    text-align: left
}

.jut__TableTitleBar__right {
    text-align: right
}

.jut__TableTitleBar__center {
    text-align: center
}

.jut__TableTitleBar__titleAction {
    flex: 0;
    margin-left: var(--table-title-action-margin-x)
}

.jut__FilterBar__searchBar {
    --filter-bar-manage-filters-button-bg-color-selected: #ededed;
    display: flex;
    align-items: center;
    padding: var(--GW-SPACING);
    background-color: var(--GW-COLOR-BACKGROUND-2)
}

.jut__FilterBar__searchBar .jut__FilterBar__searchField {
    min-width: 232px
}

.jut__FilterBar__searchBar .jut__FilterBar__manageFiltersButton {
    padding: 2px var(--GW-SPACING-XS) 0;
    border: 1px solid #7d91ac;
    box-shadow: none;
    margin-left: var(--GW-SPACING-SM)
}

.jut__FilterBar__searchBar .jut__FilterBar__manageFiltersButton:focus {
    box-shadow: var(--GW-BOX-SHADOW-FOCUS)
}

.jut__FilterBar__searchBar .jut__FilterBar__manageFiltersButtonSelected {
    background-color: var(--filter-bar-manage-filters-button-bg-color-selected)
}

.jut__FilterBar__titleBar {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.jut__FilterBar__manageFiltersBadge {
    --filter-bar-badge-bg-color: var(--GW-COLOR-BRAND-1);
    margin-left: var(--GW-SPACING-SM);
    background-color: var(--filter-bar-badge-bg-color);
    height: 100%
}

.jut__FilterBar__filterBar {
    background-color: var(--GW-COLOR-BACKGROUND-2);
    box-shadow: var(--GW-BOX-SHADOW-INNER-1);
    padding: var(--GW-SPACING) var(--GW-SPACING) 0
}

.jut__FilterBar__filterBar .jut__FilterBar__titleDivider {
    height: var(--GW-BORDER-WIDTH-THIN);
    margin: var(--GW-SPACING) 0;
    border-top: 1px solid rgba(125, 145, 172, .24)
}

.jut__FilterBar__filterTitle {
    color: var(--GW-COLOR-TEXT-1);
    font-size: var(--GW-FONT-SIZE-MD);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD)
}

.jut__FilterBar__filterItem {
    min-width: 232px;
    margin-bottom: var(--GW-SPACING-SM)
}

/*# sourceMappingURL=hashedStyles.css.map*/