@import "~@jutro/theme/assets/sass/helpers";

.tabcontiner {
  border-bottom: 1.2px solid #bbbab9;
  padding: 16px 20px;
  width: 241px;
  color: #333333;
  word-wrap: break-word;
  display: flex;
  background-color: #fff;
  --link-color: #333333;
  --GW-FONT-SIZE: 16px;
  --GW-COLOR-LINK-1: #333333;
  --GW-COLOR-LINK-1-HOVER: #333333;
  --GW-COLOR-LINK-1-ACTIVE: #333333;
  cursor: pointer;
}
.catagory {
  padding: 16px 0px 10px 20px;
  font-weight: 600;
  font-size: 16px;
}
.subcatagory:hover {
  background-color: #eff0f1;
}
.navigation-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.icon {
  color: #0076bf;
  font-size: 16px;
  padding-right: 17px;
}
.modalContainer {
  display: none;
}
.linkContainer:focus-visible,
.linkContainer:focus{
  outline: none;
  box-shadow: 0 0 var(--GW-BOX-SHADOW-FOCUS-RADIUS) var(--GW-BOX-SHADOW-FOCUS-SPREAD) #000;
}

@media screen and (max-width: 768px) {
  .leftNavigationMainContainer {
    display: none;
  }
}