@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-tablet-sidebar-width: 12rem;
}

.page {
  --gw-amp-page-width: 80%;
  --gw-amp-page-margin-bottom: 50px;
  --gw-amp-page-padding-top: 40px;
  --gw-amp-page-margin: auto;

  @include gw-breakpoint-only(tablet) {
    --gw-amp-page-width: 95%;
  }

  @include gw-breakpoint-only(phone) {
    --gw-amp-page-width: auto;
    --gw-amp-page-padding-top: 0;
    --gw-amp-page-margin-bottom: 0;
    --gw-amp-page-margin: var(--GW-SPACING) 0 var(--GW-SPACING-XL);
  }

  width: var(--gw-amp-page-width);
  margin-bottom: var(--gw-amp-page-margin-bottom);
  margin: var(--gw-amp-page-margin);
  padding-top: var(--gw-amp-page-padding-top)
}

body.themeRoot {
  background: var(--GW-COLOR-BACKGROUND-2);

 [class*='subApplicationHeader'] {
    background: var(--GW-COLOR-BACKGROUND-1);
  }
}

// theme
$fonts-root: "../assets/fonts";
//-------------------------------------
// ADD MULI FONTS FOR OSBC
//-------------------------------------
@import url("https://fonts.googleapis.com/css?family=Muli");

// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "~@jutro/theme/assets/fonts/fonts";
@import "~@jutro/theme/groundedStyles";

// ------------------------------------
//   COMMON CSS MODULES
// ------------------------------------
@import "~@jutro/components/hashedStyles";
@import "~@jutro/theme/hashedStyles";
@import "~@jutro/layout/hashedStyles";

@import "~@jutro/datatable/hashedStyles";
@import "~@jutro/router/hashedStyles";

@import "../themes/customTheme/datatable.css";
@import "../themes/customTheme/common.scss";


.jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
}

.jut__Accordion__accordionToggle {
  height: auto;
}

.jut__ModalNext__modal {
  --gw-modal-max-height: 90vh;
  > * {
    max-height: var(--gw-modal-max-height);
  }
}

.jut__FieldLabel__left {
  .jut__FieldLabel__fieldLabel {
    margin-right: calc(var(--GW-SPACING-LG) / 2);
  }
}

.skipToMainConetent{
  color: #0D73B8;
  font-weight: 600;
  text-decoration: none !important;
  position: absolute;
  top: auto;
  left: -999px;
  overflow: hidden;
  z-index: -999;
  width: auto;
  height: 1px;
  &:focus-visible{
    height: 20px;
    left: 0;
    z-index: 100;
  }
  padding-left: 5px;
}

.leftNavMainClass{
  display: flex;
  flex-direction: row;
}

.hybridLink{
  margin-bottom: 30px;
}

.pageTittle{
  font-size: 0px;
}

@include gw-breakpoint-down(phone) {
  .leftNavMainClass{
    display: block!important;
  } 
}

@include gw-breakpoint-down(tablet){
  .leftNavMainClass{
    display: flex;
  } 
}