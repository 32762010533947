@import '~@jutro/theme/assets/sass/helpers';

.osbcFooterMainContainer {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-top: 3.8147em;
  padding-bottom: 3.8147em;
}

.socialmediaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 35px 20px 35px;
}

.socialmediaIcon {
  height: 54px;
  width: 54px;
  color: #ffffff;
  opacity: 1;
  border-radius: 50px;
  text-align: center;
  margin: 16px 27px;
  line-height: 55px;
  text-decoration: none !important;
  cursor: pointer;
}

.socialmediaIcon:focus {
  outline: 1px dotted #565859;
  outline-offset: -4px;
  box-shadow: 0 0 0px 2px #000 !important;
  text-decoration: none !important;
}

.twitterIcon {
  background: #1da1f2 0% 0% no-repeat padding-box;
  border: 2px solid #1da1f2;
}
.youtubeIcon {
  border: 2px solid #ff0000;
  background: #ff0000 0% 0% no-repeat padding-box;
}
.linkedInIcon {
  border: 2px solid #0077b5;
  background: #0077b5 0% 0% no-repeat padding-box;
}
.instagramIcon {
  border: 2px solid #993399;
  background: #993399 0% 0% no-repeat padding-box;
}
.twitterIcon:hover {
  background-color: #0b76b8;
  box-shadow: 0 0 0px 2px #000 !important;
}
.youtubeIcon:hover {
  background-color: #b30000;
  box-shadow: 0 0 0px 2px #000 !important;
}
.linkedInIcon:hover {
  background-color: #004569;
  box-shadow: 0 0 0px 2px #000 !important;
}
.instagramIcon:hover {
  background-color: #993399;
  box-shadow: 0 0 0px 2px #000 !important;
}

.copyright {
  color: #ffffff;
  height: 72px;
  text-align: center;
  background: #003359 0% 0% no-repeat padding-box;
  opacity: 1;
  left: 0px;
  top: 3108px;
  line-height: 480%;
  font-size: 14px;
}

.rowdivision,
.rowdivision:hover,
.rowdivision:active {
  margin-bottom: 14px;
  font-weight: 600;
  opacity: 1;
  --GW-FONT-SIZE: 14px;
  --GW-COLOR-LINK-1: #333333;
  --GW-COLOR-LINK-1-HOVER: #333333;
}

.contactdiv {
  padding-top: 27px;
  font-weight: 300;
  font-size: 14px;
}

.staticlabel {
  font-weight: 300;
  font-size: 14px;
}

a {
  color: #333333;
  opacity: 1;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline !important;
}
/*
Footer Breakpoint for phone
*/
@include gw-breakpoint-only(phone) {
  .osbcFooterMainContainer {
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .socialmediaIcon{
    margin: 16px 15px;
  }

  .copyright {
    color: #ffffff;
    text-align: center;
    background: #003359 0% 0% no-repeat padding-box;
    opacity: 1;
    vertical-align: middle;
    line-height: 100%;
    padding:10px;
    font-size: 14px;
    word-wrap: break-word;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .socialmediaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 35px 20px 10px;
  }
}
.linkContainer:focus-visible,
.linkContainer:focus{
  outline: none;
  box-shadow: 0 0 0px 2px #000 !important;
}
/*
Footer Breakpoint for Tablet 
*/
@include gw-breakpoint-only(tablet) {
  .socialmediaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 35px 20px 10px;
  }
  .osbcFooterMainContainer {
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1023px){
    .osbcFooterMainContainer {
        width: 94%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 40px;
        padding-bottom: 40px;
      }
}
